import styled from '@emotion/styled';
import IconButton from 'components/ui/button/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SelectItem from 'components/ui/multiple-select/SelectItem';
import PdfViewerFirstPage from 'components/ui/pdf-viewer/PdfViewerFirstPage';
import PdfViewer from 'components/ui/pdf-viewer';
import loadable from 'utils/loadable';
import downloadFile from 'utils/downloadFile';
import Status from 'components/ui/status';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ValidateButton from 'components/ui/validate-button';
import Button from 'components/ui/button';
import { validateProduct } from 'utils/api';
import { useParams } from 'react-router-dom';
import { formatDate } from 'project/bat/dateCommentFormat';
import {useContext, useEffect, useState} from 'react';
import { ProjectContext, ProjectDispatchContext } from 'project/index';
import AddIcon from '@mui/icons-material/Add';
import DesignerFrame from './DesignerFrame';

const CommentAction = loadable(() => import('./CommentAction'));
const Wrapper = styled.div`
  .bat-content_name_label {
    margin-right: 25px;
  }
  .accept-bat {
    margin-top: 60px;
    margin-bottom: 25px;
  }
  .no-data {
    min-height: 115px;
    background: #fafafa;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  .no_bat {
    width: 100% !important;
    padding: 0 !important;
  }
  .taille-note-accept {
    color: #c71f2c;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 25px;
    margin-left: 53px;
    svg {
      margin-right: 7px;
    }
  }
  .text-valid {
    font-weight: 600;
    font-size: 12px;
    line-height: 130.5%;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: #003c9d;
    }
  }
  .scroll-to-taille {
    margin-left: 2px;
    text-decoration: underline;
    cursor: pointer;
  }
  @media only screen and (max-width: 440px) {
    .no-data {
      font-size: 12px;
    }
  }
`;

const dt = new Date();
const padL = (nr, len = 2, chr = '0') => `${nr}`.padStart(len, chr);

export const today = `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
  dt.getDate(),
)} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(dt.getSeconds())}`;

type BATItemProps = {
  value: string | number | object;
  bat_cid: string;
  bat_url: string;
  name: string;
  code: string;
  cpid: string;
  brand: string;
  designed_id: string;
  validate_bat_on: string;
  variant: string;
  variant_code: string;
  quantity: number;
  id: string;
  is_validate_bat: boolean;
  is_validate_taille: boolean;
  is_BO_configurable: boolean;
};

export default function BATItem({
  value,
  name,
  id: targetId,
  variant,
  designed_id,
  bat_cid: bat_cid,
  bat_url: batUrl,
  is_validate_bat: isValidateItem,
  is_validate_taille: isValidateTaille,
  validate_bat_on: validateOn,
  quantity = 0,
  variant_code: variant_code,
  code: code,
  cpid: cpid,
  is_BO_configurable: isBOconfigurable
}: BATItemProps) {
  const dispatchProject: any = useContext(ProjectDispatchContext);
  const { data: dataProject }: any = useContext(ProjectContext);
  const {
    products,
    can_validate_bat_size: canValidateBatSize,
    is_validate: isValidate,
  } = dataProject || {};
  const isValidateBAT = isValidateItem;

  const [frameSrc, setFrameSrc] = useState('');
  const [frameShow, setFrameShow] = useState(false);

  const getFileUrl = function (url, forDl = false): string {
    if (url === null) return '';
    if (forDl) return `/helpers/getfile.php?file=${url}&d=s&dl`;
    else return `/helpers/getfile.php?file=${url}&d=s`;
  };

  const { id } = useParams();

  const getQuantityLabel = function (quantityDsp): string {
    return quantityDsp === 0 ? 'Non défini' : quantityDsp;
  };

  const noPDF = Number.parseInt(designed_id) === 0 && batUrl === null  ;

  const handleValidateBat = () => {
    return validateProduct(id, { targetId, type: 'bat' }).then(() => {
      const productIndex = products.findIndex(item => +item.id === +targetId);

      if (productIndex !== -1) {
        const newProduct = [...products];
        newProduct[productIndex].is_validate_bat = true;
        newProduct[productIndex].validate_bat_on = today;
        dispatchProject({
          ...(dataProject || {}),
          products: newProduct,
        });
      }
    });
  };

  const handleScrollToTaille = () => {
    const tailleSection = document.getElementById(`taille-${targetId}`);
    document
      .querySelector('.container-project')
      ?.classList?.add('header-sticky');
    const header = document.querySelector('.header-project');
    var topOfElement =
      (tailleSection?.offsetTop || 0) - (header?.clientHeight || 0);
    window.scroll({ top: topOfElement, behavior: 'smooth' });
  };

  const getButtonTrigger = () => {
    if (isValidateBAT) {
      return (
        <div className="text-valid accept-bat">
          BAT validé {formatDate(validateOn)}
        </div>
      );
    }

    if (!canValidateBatSize || isValidate || noPDF) {
      return <></>;
    }

    return (
      <Button type="primary" className="accept-bat">
        Valider le BAT
      </Button>
    );
  };

  const getFormattedPdtName = () => {
    let variantText = variant ? `| ${variant}` : '';

    return `${name} ${variantText}`;
  };

  const toggleDesigner = () =>{
    let framesrc = `${process.env.REACT_APP_SP_URL}/designer.html?origin=lienProjet&idLienProjet=${dataProject.uniq_link}&product=${code}&chosen_decli=${variant_code}&display=nolayout&cpid=${cpid}`;
    if(bat_cid !== '' ){
      framesrc += `&idbat=${bat_cid}`;
    }
    setFrameSrc( framesrc );
    setFrameShow( true );
    return;
  };

  return (
    <Wrapper>
      <SelectItem value={value}>
        {() => (
          <>
            <div className={'bat-content card'}>
              <div className={'bat-content_name d-flex'}>
                <div
                  className={'bat-content_name_title d-flex align-item-center'}
                >
                  {/* {checkbox} */}
                  <div className={'bat-content_name_label'}>
                    {getFormattedPdtName()}
                  </div>
                  {isValidateBAT && (
                    <Status
                      colorText="#51BA0F"
                      colorBg="#D1EEBF"
                      textStatus="Validé"
                    />
                  )}
                </div>
                <div className={'bat-content_name_actions d-flex'}>
                  <PdfViewer
                    file={getFileUrl(batUrl)}
                    filename={name}
                    onDownload={() =>
                      downloadFile(getFileUrl(batUrl), `${name}.pdf`)
                    }
                    trigger={
                      <IconButton
                        icon={<VisibilityOutlinedIcon/>}
                        tooltip="Visualiser"
                      />
                    }
                  />
                  <IconButton
                    icon={<FileDownloadOutlinedIcon/>}
                    tooltip="Télécharger"
                    disable={noPDF}
                    onClick={() => downloadFile(getFileUrl(batUrl), `${name}.pdf`)}
                  />
                </div>
              </div>
              <div className={'bat-content_count'}>
                <div className={'bat-content_count_label'}>
                  Quantité : {getQuantityLabel(quantity)}
                </div>
              </div>
              {!noPDF &&
                canValidateBatSize &&
                isValidateBAT &&
                !isValidateTaille && (
                  <div className="taille-note-accept d-flex align-item-center">
                    <WarningAmberOutlinedIcon /> Vous n’avez pas encore validé
                    les tailles associées à ce produit.{' '}
                    <div
                      className="link scroll-to-taille"
                      onClick={handleScrollToTaille}
                    >
                      Cliquez ici pour valider les tailles.
                    </div>
                  </div>
                )}
              <div className={'bat-content_main d-flex'}>
                <div
                  className={`bat-content_main_left ${noPDF ? 'no_bat' : ''}`}
                >
                  {batUrl !== null && (
                    <PdfViewerFirstPage file={getFileUrl(batUrl)} />
                  )}

                  {noPDF && (
                    <div className="no-data d-flex align-item-center justify-content-center">
                      { dataProject.allow_create_bat && (
                        <>
                        { isBOconfigurable ?
                          (
                          <Button
                            type="primary"
                            icon={<AddIcon/>}
                            onClick={() =>{ toggleDesigner(); }}
                          >
                              Charger mes logos
                          </Button>
                          ):(
                            <>
                              Ce produit n'est pas configurable
                            </>
                          )}
                        </>

                      )}
                      { !dataProject.allow_create_bat && (
                        <>Le BAT est en cours de réalisation</>
                      )}
                    </div>
                    )}
                  {Number.parseInt(designed_id) < 0 && (
                    <p>Aucune personnalisation ne sera affecte à ce produit</p>
                  )}
                </div>
                {!noPDF && (
                  <div className={'bat-content_main_right'}>
                    <CommentAction
                      productId={value}
                      isValidateBat={isValidateBAT}
                    />
                    <div className={'d-flex flex-row m-3'}>
                      <div className="col-6 d-flex">
                        {!isValidateBAT && dataProject.allow_create_bat && (
                          <>
                          <Button
                              onClick={() =>{ toggleDesigner(); }}
                          >
                            Modifier le BAT
                          </Button>
                          </>
                          )
                        }
                      </div>
                      <div className="col-6 d-flex flex-row-reverse">
                        <Button
                          type="primary"
                          onClick={() => downloadFile(getFileUrl(batUrl), `${name}.pdf`)}
                        >
                          Télécharger
                        </Button>
                      </div>

                    </div>
                  </div>
                )}
              </div>
              <div className=" d-flex align-item-center justify-content-center">
                <ValidateButton
                  buttonTrigger={getButtonTrigger()}
                  onSubmit={handleValidateBat}
                  isValidate={isValidateBAT}
                  messageWarning={
                    <>
                      Ce BAT a été validé, vous ne pouvez plus faire de demande
                      de modification. <br />
                      Veuillez contacter la personne en charge de votre dossier.
                    </>
                  }
                  message={
                    <>
                      Êtes-vous sûr de vouloir valider ce BAT ? <br />
                      Une fois validé, vous ne pourrez plus faire de demande de
                      modification sur ce BAT.
                    </>
                  }
                />
              </div>
            </div>
          </>
        )}
      </SelectItem>
      <DesignerFrame
        show={frameShow}
        src={frameSrc}
        onClose = {()=>{setFrameShow(false);setFrameSrc('');return;}}
      />
      {frameShow && (<>show ok</>)}
      {frameSrc}
    </Wrapper>
  );
}
