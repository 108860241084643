import { useState } from 'react';
import isEmpty from '../../utils/isEmpty';

export default function CheckWays({ checkWaysArray, checkWaySelected }) {
  const [selectedOption] = useState(checkWaySelected);

  let span;
  if (selectedOption && !isEmpty(checkWaysArray)) {
    let translate = checkWaysArray.find(e => {
      return e.label === selectedOption;
    });
    span = <span className="fw-600 fsz-14">{translate.value_translated}</span>;
  } else {
    span = <span className="fw-600 dangerous fsz-14">Non renseignée</span>;
  }

  return (
    <div className="info-item-container d-flex flex-column">
      <span className="my-4">Moyen de paiement</span>
      {span}
    </div>
  );
}
