import styled from '@emotion/styled';
import Collapse from '../components/ui/collapse';

const Wrapper = styled.div``;

const TabItem = ({ title, id, component }) => {
  return (
    <Collapse
      key={id}
      children={component}
      id={`tab_${id}`}
      className="tab-content-item"
      header={title}
    />
  );
};

type TabsProps = {
  tabs: any;
};

const Tabs = ({ tabs }: TabsProps) => {
  return (
    <Wrapper className="container-tabs">
      {tabs.map(tab => (
        <TabItem key={tab.id} {...tab} />
      ))}
    </Wrapper>
  );
};

export default Tabs;
