import request from 'utils/request';
export const getAllInfos = ({ id, params = {} }) =>
  request(`project/get/all_infos/${id}`, { params });

export const sendProductComment = (projectId, productId, comment) =>
  request(`project/add/comment/${projectId}`, {
    method: 'POST',
    data: {
      target: 'ProductInList',
      target_id: productId,
      comment: comment,
    },
  });

export const getProductInfo = ({ projId, prodId }) => {
  let params = {
    with_comms: true,
    pdt_id: prodId,
  };

  return request(`project/get/product/${projId}`, { params });
};

export const getProducts = (projectId, params) =>
  request(`project/get/products/${projectId}`, { params });

export const updateSizeQuantity = (projectId, productId, size, quantity) =>
  request(`project/set/size_quantity/${projectId}`, {
    method: 'POST',
    data: {
      pdt_list_id: productId,
      size: size,
      quantity: quantity,
    },
  });

export const getAllAddress = ({ id, params = {} }) =>
  request(`project/get/contacts/${id}`, { params });
export const getAllCustomerContact = ({ id, params = {} }) =>
  request(`project/get/customer/${id}`, { params });
export const changeAddress = (id, data: any = {}) =>
  request(`project/set/customer_address/${id}`, {
    method: 'post',
    data,
  });
export const createAddress = (id, data: any = {}) =>
  request(`project/add/customer_infos/${id}`, {
    method: 'post',
    data,
  });

export const updateAddress = (id, data: any = {}) =>
  request(`project/update/customer_infos/${id}`, {
    method: 'post',
    data,
  });

export const generateFinalDoc = projectId =>
  request(`project/generate/documents/${projectId}?check`, {
    method: 'GET',
  });

export const receiveEmailCode = projectId =>
  request(`project/send/email/code/${projectId}`, {
    method: 'POST',
  });

export const validateProjectCode = (projectId, code) =>
  request(`project/validate/${projectId}`, {
    method: 'POST',
    data: { code: code },
  });

export const updateNickName = (projectId, pdtListId, persoId, sizeId, value) =>
  request(`project/set/persos_values/${projectId}`, {
    method: 'POST',
    data: {
      pdt_list_id: pdtListId,
      perso_id: persoId,
      size_id: sizeId,
      value: value,
    },
    params: {},
    header: {},
    isFormData: true,
  });

export const updateSizeUnit = (projectId, pdtListId, sizeId, size_unit) =>
  request(`project/set/size_unit/${projectId}`, {
    method: 'POST',
    data: {
      pdt_list_id: pdtListId,
      size_id: sizeId,
      size_unit_id: size_unit,
    },
    params: {},
    header: {},
    isFormData: true,
  });

export const removeSizeUnit = (projectId, pdtListId, size_unit) =>
  request(`project/delete/size_unit/${projectId}`, {
    method: 'POST',
    data: {
      pdt_list_id: pdtListId,
      size_unit_id: size_unit,
    },
    params: {},
    header: {},
    isFormData: true,
  });

export const downloadSizes = (projectId, pdt_id, name) =>
  request(`project/download/sizes/${projectId}`, {
    method: 'POST',
    data: {
      pdt_id: pdt_id,
      name: name,
    },
  });

export const deleteSizesTempFiles = projectId =>
  request(`project/delete/files/sizes/${projectId}`, {
    method: 'DELETE',
  });

export const addLog = (projectId, source, source_id, log_type, options = {}) =>
  request(`project/add/log/${projectId}`, {
    method: 'POST',
    data: {
      source: source,
      source_id: source_id,
      log_type: log_type,
      options: options,
    },
    isFormData: true,
  });

export const getServerInfos = () =>
  request('server/infos', {
    method: 'GET',
  });

export const validateProduct = (id, data) =>
  request(`project/validate/product/${id}`, {
    method: 'POST',
    data,
  });

export const getComments = (projectId, target = undefined) =>
  request(`project/get/comment/${projectId}`, {
    params: { target },
  });

export const addComments = (projectId, { target, target_id, comment }) =>
  request(`project/post/comment/${projectId}`, {
    method: 'POST',
    data: {
      target,
      target_id,
      comment,
    },
  });

export const getCheckWays = () => {
  return new Promise((resolve, reject) => {
    request('project/get_check_ways', {})
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateProjectCustomValues = (projectId, { which_info, value }) => {
  request(`project/update_custom_values/${projectId}`, {
    method: 'POST',
    data: {
      which_info,
      value,
    },
  });
};
