import styled from '@emotion/styled';
import {useEffect, useState} from 'react';
import Button from 'components/ui/button';

type DesignerFrameProps = {
  src: string;
  show: boolean;
  onClose: () => void;
}
export default function DesignerFrame({
  src: src = '',
  show: show,
  onClose
}:DesignerFrameProps){

  const Wrapper = styled.div`
    .designer-container{
      position: fixed;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: white;
    }
    .designer-iframe{
      width: 100%;
      height: 100%;
    }

    .bottom-right{
      position: fixed;
      bottom: 15px;
      right: 0;
      width: 20%;
      text-align: center;
      max-width: 360px;
      margin:0;
      padding: 0;
    }

    @media only screen and (max-width: 1100px) {
      .bottom-right{
        left: 20px;
      }
    }
  `;
  const [designerCssClass, setDesignerCssClass] = useState('designer-container hidden');
  useEffect(() => {
    // Update class based on `show` prop
    setDesignerCssClass(show ? 'designer-container' : 'designer-container hidden');
  }, [show]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'sp_configSaved') {
        setDesignerCssClass('designer-container hidden');
        onClose();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

 return (
   <Wrapper>
     <div className={designerCssClass}>
       <div className={'bottom-right'}>
         <Button
           className={'designer-close-button'}
           onClick={()=>{ onClose();}}>Annuler</Button>
       </div>
       <iframe
         className={'designer-iframe'}
         src={src}/>
     </div>
   </Wrapper>
 );
}
