import styled from '@emotion/styled';
import MultipleSelect from 'components/ui/multiple-select';
import DocumentItem from 'project/document/DocumentItem';
import { useContext } from 'react';
import { ProjectContext } from 'project/index';
const Wrapper = styled(MultipleSelect)`
  .items {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
    padding: 0 8px;
  }
  .no-data {
    min-height: 115px;
    background: #fafafa;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    margin: 20px 32px;
    @media only screen and (max-width: 768px) {
      margin: 20px 20px 0;
    }
    @media only screen and (max-width: 440px) {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 600px) {
  }
`;

export default function Document() {
  const { data }: any = useContext(ProjectContext);
  const documents = data.documents || [];

  const getFileUrl = function (url, forDl = false): string {
    if (url === null) return '';
    if (forDl) return `/helpers/getfile.php?file=${url}&d=s&dl`;
    else return `/helpers/getfile.php?file=${url}&d=s`;
  };

  return (
    <Wrapper onClickAction={a => console.log(a)}>
      {documents.length ? (
        documents.map(
          ({
            version_id,
            name,
            name_user,
            file_url,
            type,
            has_comment_document,
            associated_product,
            project_file_id: projectFileId,
          }) =>
            !(type === 'cs_bat' && associated_product === '1') && (
              <DocumentItem
                version_id={version_id}
                projectFileId={projectFileId}
                value={file_url}
                key={file_url}
                hasComment={has_comment_document}
                documentName={name_user !== null ? name_user : name}
                fileUrl={getFileUrl(file_url)}
                dlFileUrl={getFileUrl(file_url, true)}
              />
            ),
        )
      ) : (
        <div className="no-data d-flex align-item-center justify-content-center">
          Aucun document n'a été ajouté.
        </div>
      )}
    </Wrapper>
  );
}
