import styled from '@emotion/styled';
import { ReactElement, useEffect, useState} from 'react';
import React from 'react';
import Select from '../../components/ui/select';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import he from 'he';
const Wrapper = styled.div`
  .view-more-row {
    height: 45px;
    .view-more {
      color: #003c9d;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 170.5%;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .table {
    width: 100%;
    flex-wrap: wrap;
    border: 1px solid #e9e7ff;
    border-radius: 5px;
    .thead {
      width: 100%;
      border-bottom: 1px solid #e9e7ff;
      .tr {
        display: flex;
        .th {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          & + .th {
            border-left: 1px solid #e9e7ff;
          }
        }
      }
    }
    .tbody {
      width: 100%;
      .tr {
        display: flex;
        & + .tr {
          border-top: 1px solid #e9e7ff;
        }
        .td {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          & + .td {
            border-left: 1px solid #e9e7ff;
          }
          input {
            background: #ffffff;
            border: none;
            border-radius: 5px;
            width: 100%;
            padding: 5px;
            text-align: left;
          }
          input:focus {
            background: #ffffff;
            border: 1px solid #e9e7ff;
            border-radius: 5px;
            width: 100%;
            padding: 5px;
            text-align: left;
          }
        }
      }
    }
    .remove-icon {
      position: relative;
      left: -18px;
      margin-top: 10px;
      margin-left: -18px;
      cursor: pointer;
      color: #003c9d;
      opacity: 0;
      &.for-remove {
        opacity: 1;
      }
    }
    .tr {
      .th,
      .td {
        min-height: 45px;
        vertical-align: middle;
      }
      .th {
        background: #f6f5fb;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }
      .td {
        text-align: center;
      }
    }
  }
  .table {
    .thead {
      .tr {
        .th {
          justify-content: start;
          padding: 0 15px;

          &:first-of-type {
            width: 185px;
            max-width: 185px;
            @media only screen and (max-width: 600px) {
              width: 170px;
              max-width: 170px;
            }
            @media only screen and (max-width: 500px) {
              flex: auto;
            }
          }
        }
      }
    }

    .tbody {
      .tr {
        .td {
          justify-content: start;
          padding: 4px 15px;
          @media only screen and (max-width: 600px) {
            padding: 4px;
          }
          &:first-of-type {
            width: 185px;
            max-width: 185px;
            @media only screen and (max-width: 600px) {
              width: 170px;
              max-width: 170px;
            }
            @media only screen and (max-width: 500px) {
              flex: auto;
            }
          }
          &.span-all {
            max-width: 100%;
            justify-content: center;
          }
          &.add-row {
            cursor: pointer;
          }
        }
      }
    }
  }
`;

type TableProps = {
  pdtQuantity: Number;
  titles: any;
  sizes: any;
  data: any;
  syncDataSizesCallback: any;
  addNewSizeCallback: any;
  removeSizeCallback: any;
  lastACtionComponent?: ReactElement;
};

export default function ListTablePopIn({
  pdtQuantity,
  titles,
  sizes,
  data,
  syncDataSizesCallback,
  addNewSizeCallback,
  removeSizeCallback,
  lastACtionComponent,
}: TableProps) {
  const [dataSizes, setDataSizes] = useState(data);
  useEffect(() => {
    setDataSizes(data);
  });
  const [timer, setTimer] = useState<number | null>(null);
  const isCustom = !lastACtionComponent && pdtQuantity === 0;
  const sizesOptions: any = sizes?.map(item => ({
    label: item.size,
    value: item.id,
  }));

  const checkSize = e => {
    let size = e.target.getAttribute('data-size');
    if (size > 0) return;
    e.target.blur();
  };

  const addRow = () => {
    let newData = {};
    titles.forEach(element => {
      newData[element.label] = '';
    });

    addNewSizeCallback(newData);
  };

  const removeRow = e => {
    removeSizeCallback(e.target.closest('div.td').getAttribute('data-size'));
  };

  const showRemoveIcon = e => {
    var allRm = document.querySelectorAll('.remove-icon');
    allRm.forEach(function (el) {
      el.classList.remove('for-remove');
    });

    let element =
      e.target.className !== 'tr' ? e.target.closest('div.tr') : e.target;
    let removeIcon = element.firstChild.firstChild;
    if (!removeIcon.className.includes('remove-icon')) return;

    removeIcon.classList.add('for-remove');
  };
const handleOnChange = (key, row, new_value , index) => {
  dataSizes[index][key].value = new_value;
  setDataSizes(dataSizes); 
  if (timer)
    clearTimeout(timer);
  setTimer(setTimeout(e => {syncDataSizesCallback(row[key], key, new_value, index);}, 500));
};

  return (
    <Wrapper>
      <div className="table">
        <div className="thead">
          <div className="tr">
            {titles.map(({ label, classStyle }) => (
              <div key={label} className={`${classStyle} th`}>
                {' '}
                {label}{' '}
              </div>
            ))}
          </div>
        </div>
        <div className="tbody">
          {dataSizes.map((row, ii) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="tr" onMouseEnter={showRemoveIcon} key={ii}>
              {Object.keys(row).map(key =>
                key === 'Taille' ? (
                  <>
                    <div
                      className="td"
                      key={key}
                      data-size={row.Taille.size_unit}
                    >
                      <div
                        className={`${isCustom ? '' : 'hidden'} remove-icon`}
                      >
                        <RemoveCircleIcon onClick={removeRow} />
                      </div>
                      <Select
                        options={sizesOptions}
                        defaultValue={row[key].value}
                        value={row[key].value}
                        onSelect={value =>
                          syncDataSizesCallback(row[key], key, value, ii)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div className="td" key={key}>
                    <input
                      type={'text'}
                      data-key={key}
                      data-size={row.Taille.size_unit}
                      value={he.decode(row[key].value)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(key, row, event.target.value, ii)}
                      onFocus={checkSize}
                      defaultValue={row[key].value}
                      className="input_size_perso"
                    />
                  </div>
                ),
              )}
            </div>
          ))}
          {isCustom && (
            <div className="tr">
              <div className="td span-all add-row" onClick={addRow}>
                {' '}
                <AddIcon /> Ajouter une ligne{' '}
              </div>
            </div>
          )}
          {lastACtionComponent ? (
            <>
              <div className="tr view-more-row d-flex align-items-center justify-content-center">
                <div className="colspan">{lastACtionComponent}</div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </Wrapper>
  );
}
