import styled from '@emotion/styled';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useEffect, useRef, useState } from 'react';
import throttle from 'utils/throttle';

const Wrapper = styled.div``;

type PdfViewerProps = {
  file: string;
};

export default function PdfViewerFirstPage({ file }: PdfViewerProps) {
  const [initialWidth, setInitialWidth] = useState(0);
  const pdfWrapper = useRef(null);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      // @ts-ignore
      setInitialWidth(pdfWrapper.current?.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 1000));
    setPdfSize();

    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 1000));
    };
  }, []);

  return (
    <Wrapper ref={pdfWrapper}>
      <Document file={file}>
        <Page pageNumber={1} renderTextLayer={false} width={initialWidth} />
      </Document>
    </Wrapper>
  );
}
