import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import TaillesItem from 'project/tailles/TaillesItem';
import MultipleSelect from 'components/ui/multiple-select';
import { ProjectContext } from 'project';

const Wrapper = styled(MultipleSelect)`
  padding: 12px 12px 12px 0;
  .checkbox-label {
    margin-right: 10px;
  }
  .tailles-header {
    padding-left: 21px;
  }
  .tailles-content {
    .tailles-content_name {
      .tailles-content_name_title {
        .client-info_left_content_name {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 160%;
          color: #000000;
          margin-left: 8px;
        }
        .client-info_left_content_quantity {
          padding-left: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #000000;
        }
      }
      .tailles-content_name_logo {
        img {
          width: auto;
          max-height: 80px;
          margin: 0 30px;
          max-width: 80px;
        }
      }
      .tailles-content_name_actions {
        label {
          width: 50%;
        }
      }
    }
    .tailles-content_size,
    .tailles-content_list {
      margin-left: 53px;
      margin-right: 53px;
    }
    .tailles-content_count {
      margin: 10px 0;
      .tailles-content_count_label {
        padding-left: 38px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130.5%;
        color: #000000;
      }
      .tailles-content_name_actions {
      }
    }
    .tailles-content_main {
      padding: 0 38px;
      .tailles-content_main_left {
        width: 60%;
        padding-right: 50px;
        img {
          width: 100%;
        }
      }
      .tailles-content_main_right {
        background: #f9f9f9;
        border-radius: 5px;
        width: 40%;
        padding: 20px 15px;
        .tailles-content_main_history_item {
          height: calc(100% - 100px);
          p {
            margin-top: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130.5%;
            color: #000000;
          }
          div {
            margin-top: 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130.5%;
            color: #909090;
          }
        }
        .tailles-content_main_send_box {
          height: 100px;
          .tailles-content_main_send_box_content {
            width: 85%;
          }
          .tailles-content_main_send_box_button {
            margin-left: 2rem;
            width: 10%;
            padding: 27px 4px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .tailles-content {
      .tailles-content_size,
      .tailles-content_list {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .table {
      .tbody {
        .tr {
          .td {
            input {
              width: 3rem;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .tailles-content {
      padding: 20px 15px !important;
      .tailles-content_name {
        .tailles-content_name_logo {
          img {
            margin-right: 15px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .tailles-content {
      .tailles-content_name {
        .tailles-content_name_title {
          .client-info_left_content_name,
          .client-info_left_content_quantity {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .tailles-content {
      padding: 20px 15px !important;
      .tailles-content_list {
        border: 0;
        padding: 0;
        .buttons {
          display: flex;
          justify-content: space-between;
          width: 100%;
          button {
            max-width: 130px !important;
            min-width: 130px !important;
            margin: 0 !important;
            float: none !important;
          }
        }
      }
      .tailles-content_name {
        flex-wrap: wrap;
        .tailles-content_name_title {
          .client-info_left_content_name,
          .client-info_left_content_quantity {
            font-size: 12px;
          }
        }
        .tailles-content_name_logo {
          img {
            margin: 0 10px;
          }
        }
      }
    }
  }
`;

type TaillesProps = {
  onQuantitiesChangeCallback: Function;
};

export default function Tailles({ onQuantitiesChangeCallback }: TaillesProps) {
  const { data }: any = useContext(ProjectContext);
  const { products } = data || [];
  const [validTotal, setValidTotal] = useState({});

  function handleSizeChange(id, name, quantity, total) {
    let valid = 0;

    if (quantity === total) {
      valid = 0; // OK
    } else if (quantity < total && total > 0) {
      valid = 1; // Inferieur
    } else if (quantity > total && total > 0) {
      valid = 2; // Superieur
    }

    validTotal[id] = {
      valid: valid,
      name: name,
      quantity: quantity,
      total: total,
    };
    setValidTotal(validTotal);
    onQuantitiesChangeCallback(validTotal);
  }

  return (
    <Wrapper>
      {products.map(pdt => (
        <TaillesItem
          key={pdt.id}
          pdt={pdt}
          value={1}
          onQuantityChangeCallback={quantity =>
            handleSizeChange(pdt.id, pdt.name, quantity, pdt.quantity)
          }
        />
      ))}
    </Wrapper>
  );
}
