import { useState } from 'react';

export default function PaymentTo({ paymentToSelected }) {
  const [paymentTo] = useState(paymentToSelected);
  let span;

  if (paymentTo) {
    span = <span className="fw-600 fsz-14">{paymentTo}</span>;
  } else {
    span = <span className="fw-600 dangerous fsz-14">Non renseignée</span>;
  }

  return (
    <div className="info-item-container d-flex flex-column">
      <span className="my-4">Paiement à</span>
      {span}
    </div>
  );
}
