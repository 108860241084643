import React, { useContext, useRef, useState } from 'react';
import TextArea from 'components/ui/textarea';
import IconButton from 'components/ui/button/IconButton';
import SendIcon from 'components/ui/icon/SendIcon';
import styled from '@emotion/styled';
import { addComments, getComments } from 'utils/api';
import style from 'contanst/style';
import {
  CommentContext,
  CommentDispatchContext,
  ProjectContext,
  ProjectDispatchContext,
} from 'project/index';
import { useParams } from 'react-router-dom';
import {
  TARGET_DOCUMENT_COMMENT,
  TARGET_INFORMATION_COMMENT,
  TARGET_TAILLE_COMMENT,
} from 'project/comments/index';
import PopinWarning from '../../components/ui/modal/PopinWarning';
const Wrapper = styled('div')`
  padding: 24px 70px;
  height: 135px;
  background: #f6f5fb;
  align-self: flex-end;
  width: 100%;

  textarea {
    height: 100%;
    width: 100%;
    border: solid 1px #909090;
    border-radius: 5px;
    resize: none;
    padding: 8px;
  }
  .send-comment {
    width: 35px;
    height: 35px;
    background: ${style.primaryColor};
    border-radius: 50%;
    margin-left: 20px;
    display: block;
    svg {
      width: 35px;
      height: 35px;
    }
    &.loading {
      pointer-events: none;
      cursor: not-allowed;
      display: flex;
      align-items: center;
      min-width: 35px;
      min-height: 35px;
      justify-content: center;
      svg {
        color: #ffffff;
        width: 22px !important;
        height: 22px !important;
      }
    }
  }

  .item-validated {
    cursor: not-allowed;
    svg {
      color: ${style.disabledColor};
    }
  }
`;

const CommentAction = () => {
  const { id } = useParams();
  const refTextArea = useRef<any>();
  const data: any = useContext(CommentContext);
  const dispatch: any = useContext(CommentDispatchContext);
  const { data: dataProject }: any = useContext(ProjectContext);
  const dispatchProject: any = useContext(ProjectDispatchContext);
  const { comments, type, targetId, isValidate, hasPerson } = data || {};
  const [sendingComment, setSendingComment] = useState(false);
  const {
    products,
    has_comment_informations: hasCommentInformation,
    documents,
    is_validate: isValidateProject,
  } = dataProject || {};

  const loadComments = () => {
    getComments(id, type)
      .then(res => {
        refTextArea.current.value = '';
        dispatch({ comments: { ...comments, ...res } });

        //change icon comment
        if (type === TARGET_INFORMATION_COMMENT && !hasCommentInformation) {
          dispatchProject({
            ...(dataProject || {}),
            has_comment_informations: true,
          });
        }

        if (type === TARGET_TAILLE_COMMENT) {
          const productIndex = products.findIndex(
            item => +item.id === +targetId,
          );

          if (productIndex !== -1) {
            const product = products[productIndex];
            const newProduct = [...products];
            newProduct[productIndex].has_comment_taille = true;
            !product.has_comment_taille &&
              dispatchProject({
                ...(dataProject || {}),
                products: newProduct,
              });
          }
        }

        if (type === TARGET_DOCUMENT_COMMENT) {
          const documentIndex = documents.findIndex(
            item => +item.project_file_id === +targetId,
          );

          if (documentIndex !== -1) {
            const document = documents[documentIndex];
            const newDocuments = [...documents];
            newDocuments[documentIndex].has_comment_document = true;
            !document.has_comment_document &&
              dispatchProject({
                ...(dataProject || {}),
                documents: newDocuments,
              });
          }
        }
      })
      .finally(() => setSendingComment(false));
  };

  const sendProductCommentAction = () => {
    if (
      !refTextArea.current?.value?.trim() ||
      isValidate ||
      isValidateProject
    ) {
      return;
    }

    setSendingComment(true);
    addComments(id, {
      comment: refTextArea.current.value,
      target: type,
      target_id: targetId || TARGET_INFORMATION_COMMENT,
    }).then(loadComments);
  };

  const textArea = (
    <TextArea
      placeholder="Ajouter un commentaire"
      ref={refTextArea}
      onClick={e => {
        (isValidate || isValidateProject) && e.target.blur();
      }}
      //disabled={isValidate || isValidateProject}
    />
  );
  const btnSentComment = (
    <IconButton
      className={`send-comment ${
        isValidate || isValidateProject ? 'item-validated' : ''
      }`}
      loading={sendingComment}
      icon={<SendIcon />}
      onClick={sendProductCommentAction}
    />
  );

  let messageWarning = (
    <>
      Le dossier est validé, vous ne pouvez plus ajouter de commentaire sur les
      éléments.
      <br />
      Veuillez contacter la personne en charge de votre dossier.
    </>
  );

  if (type === TARGET_TAILLE_COMMENT && !isValidateProject) {
    messageWarning = hasPerson ? (
      <>
        Les tailles et personnalisations individuelles ont été validées.
        <br />
        Vous ne pouvez plus ajouter des commentaires.
        <br />
        Veuillez contacter la personne en charge de votre dossier.
      </>
    ) : (
      <>
        Les tailles ont été validées.
        <br />
        Vous ne pouvez plus ajouter des commentaires.
        <br />
        Veuillez contacter la personne en charge de votre dossier.
      </>
    );
  }

  return (
    <Wrapper className="comment-input d-flex align-item-center">
      {isValidate || isValidateProject ? (
        <PopinWarning
          buttonTrigger={textArea}
          messageWarning={messageWarning}
        />
      ) : (
        textArea
      )}
      {isValidate || isValidateProject ? (
        <PopinWarning
          buttonTrigger={btnSentComment}
          messageWarning={messageWarning}
        />
      ) : (
        btnSentComment
      )}
    </Wrapper>
  );
};

export default CommentAction;
