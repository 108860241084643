import styled from '@emotion/styled';

const Wrapper = styled('div')<{}>`
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: 500;
  font-size: 12px;
  height: 24px;
  min-width: 175px;
  border-radius: 15px;
  padding: 0 8px;
  line-height: 24px;
`;

type Props = {
  colorText: string;
  colorBg: string;
  textStatus: string;
  className?: string;
};

export default function Status({
  colorText,
  colorBg,
  textStatus,
  className = '',
}: Props) {
  return (
    <Wrapper
      className={className}
      style={{ color: colorText, background: colorBg }}
    >
      {textStatus}
    </Wrapper>
  );
}
