import React from 'react';
import styled from '@emotion/styled';
import Collapse from 'components/ui/collapse';
import CommentItem from './CommentItem';
const Wrapper = styled(Collapse)``;
type Props = {
  open: boolean;
  tailleComments: any[];
};

const CommentTailles = ({ open, tailleComments }: Props) => {
  return (
    <Wrapper
      header="Tailles"
      enableCollapse
      className="comment-section"
      defaultOpen={open}
    >
      {!tailleComments?.length ? (
        <div className="txt-empty-comment">
          Aucun commentaire pour la section "Tailles".
        </div>
      ) : (
        tailleComments.map(({ id, comment, title, created_on: date }) => (
          <CommentItem key={id} comment={comment} title={title} date={date} />
        ))
      )}
    </Wrapper>
  );
};

export default CommentTailles;
