import styled from '@emotion/styled';
import { ReactElement, ReactNode, useState } from 'react';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Modal from 'components/ui/modal';
import Button from 'components/ui/button';

const Wrapper = styled(Modal)`
  .rc-dialog-body {
    padding: 20px 40px !important;
    .message-modal {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #003c9d;
      margin: 40px 0 55px;
      svg {
        color: #c71f2c;
        margin-right: 15px;
        align-self: start;
      }
    }
    button {
      min-width: 187px;
    }

    @media only screen and (max-width: 900px) {
      width: 500px !important;
    }
    @media only screen and (max-width: 530px) {
      button {
        min-width: 150px;
      }
      width: calc(100vw - 30px) !important;
    }
    @media only screen and (max-width: 450px) {
      padding: 20px !important;
    }
  }
`;

type Props = {
  buttonTrigger: ReactElement;
  message?: ReactNode;
  messageWarning?: ReactNode;
  isValidate?: boolean;
  children?: ((onClose) => void) | ReactNode;
  className?: string;
  height?: number | string;
  width?: number | string;
  onSubmit: () => Promise<any>;
};

export default function ValidateButton({
  message,
  buttonTrigger,
  isValidate,
  onSubmit,
  messageWarning,
  ...extraProps
}: Props) {
  const [loading, setLoading] = useState(false);

  const handleClickAccept = closeModal => {
    setLoading(true);
    onSubmit()
      .then(() => closeModal())
      .finally(() => setLoading(false));
  };

  return (
    <Wrapper triggerModal={buttonTrigger} width={530} {...extraProps}>
      {handleClose => {
        return (
          <>
            {isValidate ? (
              <div className="message-modal d-flex align-content-start">
                <WarningAmberOutlinedIcon />
                <div>{messageWarning}</div>
              </div>
            ) : (
              <>
                <div className="message-modal">{message}</div>
                <div className="submit-form d-flex justify-content-between ">
                  <Button type="default" onClick={handleClose}>
                    Annuler
                  </Button>

                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    onClick={() => handleClickAccept(handleClose)}
                  >
                    Valider
                  </Button>
                </div>
              </>
            )}
          </>
        );
      }}
    </Wrapper>
  );
}
