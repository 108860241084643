import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  input[readonly] {
    border: none !important;
  }
`;

type SizeTableProps = {
  data: any;
  dataSizes: any;
  updateSizes: any;
  total: number;
  type: string;
  isValidate;
};

export default function SizeTable({
  data,
  dataSizes,
  updateSizes,
  total,
  type,
  isValidate,
}: SizeTableProps) {
  let sumQuantity = data.reduce((sumQuantityq, size) => {
    let quantity = parseInt(size.quantity);
    quantity = isNaN(quantity) ? 0 : quantity;

    return sumQuantityq + quantity;
  }, 0);

  const [sumQuantityRate, setSumQuantityRate] = useState(sumQuantity);
  useEffect(() => {
    setSumQuantityRate(sumQuantity);
  });

  return (
    <Wrapper className="d-flex table">
      <div className="thead">
        <div className="tr">
          {data.map((size, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="th" key={i}>
              {' '}
              {size.value}{' '}
            </div>
          ))}
          <div className="th bg-white">Total</div>
        </div>
      </div>
      <div className="tbody">
        <div className="tr">
          {data.map((size, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="td" key={i}>
              <input
                readOnly={parseInt(dataSizes.length) !== 0}
                type={'text'}
                disabled={isValidate}
                onChange={element => {
                  updateSizes(
                    i,
                    element.target,
                    size.value,
                    setSumQuantityRate,
                  );
                }}
                onKeyDown={event => {
                  const re = /^[0-9\b]+$/;

                  if (!re.test(event.key) && event.key !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
                value={size.quantity}
              />
            </div>
          ))}
          <div className="td total">
            <div>
              <span className={type}>{sumQuantityRate}</span>
              <span>{total !== 0 ? `/${total}` : ''}</span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
