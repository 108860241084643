import styled from '@emotion/styled';
import clsx from 'clsx';
import style from 'contanst/style';
const Wrapper = styled.div`
  .email {
    color: ${style.primaryColor};
    a {
      color: ${style.primaryColor};
      text-decoration: unset;
    }
  }
  .error {
    color: #c71f2c;
  }
  position: relative;
  height: 100%;
  .icon-button {
    position: absolute;
    right: 10px;
  }

  .info-item {
    height: 100%;
  }
  .box-info {
    padding-right: 25px;
  }
`;

type ContactProps = {
  title?: string;
  className?: string;
  contact: any;
  contact_empty: boolean;
  isValidate;
};

export default function Contact({
  title = 'Informations de contact',
  className = '',
  contact,
  contact_empty,
}: ContactProps) {
  const { email, phone, poste } = contact;

  return (
    <div className="info-item-container">
      <Wrapper className={clsx(className, 'info-item')}>
        <div className="title-information">{title}</div>
        {!contact_empty ? (
          <div className="box-info">
            <div className="fullName bold d-flex align-item-center justify-content-between">
              {contact.firstname} {contact.lastname}
              {/*{!isValidate && <DropdownMenu*/}
              {/*  menuItems={[*/}
              {/*    { renderMenu: <FormEditContact initialValues={contact} /> },*/}
              {/*  ]}*/}
              {/*/>*/}
              {/*}*/}
            </div>
            <div className="poste">{poste}</div>
            <div className="phone">{phone}</div>
            <div className="email">
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
        ) : (
          <div className="box-info">
            <div className="bold d-flex align-item-center justify-content-between error">
              Aucun contact assigné
            </div>
          </div>
        )}
      </Wrapper>
    </div>
  );
}
//
// const FormEditContact = ({ initialValues }) => {
//   const { id } = useParams();
//   const menu = (
//     <MenuItem className="menu-item d-flex align-item-center pointer">
//       <span className="icon">
//         <EditOutlinedIcon />
//       </span>
//       <span className="label">Editer le contact</span>
//     </MenuItem>
//   );
//
//   const handleSubmit = (values, onCancel) => {
//     return createAddress(id, values).then(() => onCancel());
//   };
//
//   return (
//     <Modal triggerModal={menu} title="Editer le contact">
//       {onClose => (
//         <Form
//           onSubmit={handleSubmit}
//           onCancel={onClose}
//           initialValues={initialValues}
//         >
//           <div className="row">
//             <div className="col-6">
//               <Field name="firstname" required label="Prénom" />
//             </div>
//             <div className="col-6">
//               <Field name="lastname" required label="Nom" />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-12">
//               <Field name="phone" label="Numéro de téléphone" />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-12">
//               <Field name="poste" label="Poste occupé" />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-12">
//               <Field name="email" label="Email" required />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-12">
//               <Field name="address" label="Adresse" />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-6">
//               <Field name="zip" label="Code postal" />
//             </div>
//             <div className="col-6">
//               <Field name="town" label="Ville" />
//             </div>
//           </div>
//         </Form>
//       )}
//     </Modal>
//   );
// };
