import styled from '@emotion/styled';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import {
  cloneElement,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import style from 'contanst/style';

const WrapperDialog = styled(Dialog)`
  margin: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .rc-dialog-content {
    // height: 100%;
    //width: 100%;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 80px);
    min-width: 500px;
    overflow: auto;
    .rc-dialog-header {
      padding: 25px 30px 20px;
      border: 0;
      .rc-dialog-title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        line-height: inherit;
        font-size: 30px;
        color: ${style.primaryColor};
        @media only screen and (max-width: 500px) {
          font-size: 24px;
        }
      }
    }
    .rc-dialog-close {
      font-size: 24px;
      color: ${style.primaryColor};
      opacity: 1;
      margin-right: -15px;
      * {
        width: 24px;
        height: 24px;
        color: ${style.primaryColor};
        opacity: 1;
      }
    }
    .rc-dialog-body {
      padding: 30px;
      flex: 1;
      height: 100%;
      overflow: auto;
    }
    .submit-form {
      margin-top: 40px;
    }
  }
  @media only screen and (max-width: 900px) {
    width: calc(100vw - 100px) !important;
    &.rc-dialog {
      margin: auto;
    }
  }
  @media only screen and (max-width: 768px) {
    .col-6 {
      width: 100%;
      max-width: 100%;
    }
    .row {
      margin: 0 -10px;
      .field-container {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    width: calc(100vw - 30px) !important;
    .rc-dialog-content {
      min-width: calc(100vw - 20px);
    }
  }
`;

type ModalProps = {
  triggerModal: ReactElement;
  title?: ReactNode;
  children: ((onClose) => void) | ReactNode;
  className?: string;
  height?: number | string;
  width?: number | string;
  wrapClassName?: string;
};

export default function Modal({
  triggerModal,
  title,
  children,
  width = 900,
  className = '',
  ...extraProps
}: ModalProps) {
  const [open, setOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const handleClickOpen = useCallback((event: any) => {
    event?.stopPropagation?.();
    setMousePosition({
      x: event?.pageX || event?.domEvent?.pageX,
      y: event?.pageY || event?.domEvent?.pageY,
    });
    setOpen(true);
  }, []);

  const handleClose = useCallback((event?: any) => {
    event?.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <>
      {cloneElement(triggerModal, {
        onClick: function (event) {
          handleClickOpen(event);
          if (typeof triggerModal.props.onClick !== 'undefined')
            triggerModal.props.onClick(event);
        },
      })}
      <WrapperDialog
        title={title}
        onClose={handleClose}
        mousePosition={mousePosition}
        destroyOnClose
        visible={open}
        animation="zoom"
        className={className}
        width={width}
        closeIcon={<CloseOutlinedIcon />}
        maskAnimation="fade"
        {...extraProps}
      >
        {typeof children === 'function' ? children(handleClose) : children}
      </WrapperDialog>
    </>
  );
}
