export default function SendIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="17" fill="currentColor" />
      <mask
        id="mask0_12574_7065"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="18"
        height="18"
      >
        <rect x="8" y="8" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12574_7065)">
        <path
          d="M10.25 23V11L24.5 17L10.25 23ZM11.75 20.75L20.6375 17L11.75 13.25V15.875L16.25 17L11.75 18.125V20.75ZM11.75 20.75V13.25V18.125V20.75Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
