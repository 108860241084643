import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import { ProjectContext } from 'project';

const Wrapper = styled.div`
  position: sticky;
  top: 10px;
  z-index: 100;
  #notification_banner {
    margin-top: 10px;
    background: #bddac7;
    color: #238443;
    width: 100%;
    height: 56px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 10px;
    padding: 16px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notification_text {
    font-family: 'Montserrat';
  }
  .notification_close {
    cursor: pointer;
  }
`;

export default function BannerProjectValidated() {
  const { data }: any = useContext(ProjectContext);
  const isValidate = data.is_validate || false;
  const [isDisplay, setIsDisplay] = useState(isValidate);

  useEffect(() => {
    if (isDisplay) {
      document
        .querySelector('.container-project')
        ?.classList.add('has_banner_validated');
    } else {
      document
        .querySelector('.container-project')
        ?.classList.remove('has_banner_validated');
    }
  }, [isDisplay]);

  const hideBanner = () => {
    setIsDisplay(false);
  };

  return (
    <Wrapper>
      {isDisplay && (
        <div id={'notification_banner'}>
          <p className="notification_text">
            Le dossier a été validé. Vous êtes en lecture seule.
          </p>
          <CloseIcon onClick={hideBanner} className="notification_close" />
        </div>
      )}
    </Wrapper>
  );
}
