import React from 'react';
import styled from '@emotion/styled';
import Collapse from 'components/ui/collapse';
import CommentItem from './CommentItem';
const Wrapper = styled(Collapse)``;
type Props = {
  open: boolean;
  informationComments: any[];
};

const CommentInformation = ({ open, informationComments }: Props) => {
  return (
    <Wrapper
      header="Informations"
      enableCollapse
      className="comment-section"
      defaultOpen={open}
    >
      {!informationComments?.length ? (
        <div className="txt-empty-comment">
          Aucun commentaire pour la section "Informations".
        </div>
      ) : (
        informationComments.map(({ id, comment, title, created_on: date }) => (
          <CommentItem key={id} comment={comment} title={title} date={date} />
        ))
      )}
    </Wrapper>
  );
};

export default CommentInformation;
