import styled from '@emotion/styled';
import Modal from 'components/ui/modal';
import { useEffect, useState } from 'react';
import ListTablePopIn from './ListTablePopIn';

const Wrapper = styled(Modal)`
  .note-confirm-code {
    font-weight: 500;
    font-size: 12px;
    margin: 30px 0 30px;
    color: #000000;
  }
  .rc-dialog-body {
    height: 100%;
    overflow-y: auto;
    padding: 20px !important;
    margin: 20px;
    table {
      tr {
        th,
        td {
          padding-left: 10px;
          text-align: left !important;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .table {
      .thead {
        .tr {
          .th {
            &:nth-of-type(3),
            &:nth-of-type(4) {
              display: none;
            }
          }
        }
      }
      .tbody {
        .tr {
          .td {
            &:nth-of-type(3),
            &:nth-of-type(4) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

type SignatureFormProps = {
  pdtQuantity: Number;
  sizes: any;
  titles: any;
  data: any;
  syncDataSizesCallback: any;
  addNewSizeCallback: any;
  removeSizeCallback: any;
};

export default function ListTailles(props: SignatureFormProps) {
  const [dataSizes, setDataSizes] = useState(props.data);
  useEffect(() => {
    setDataSizes(props.data);
  });

  return (
    <Wrapper
      triggerModal={<span className={'view-more'}>Voir plus</span>}
      title="Listes des personnalisations individuelles"
      height={700}
      width={1200}
    >
      {() => (
        <ListTablePopIn
          pdtQuantity={props.pdtQuantity}
          sizes={props.sizes}
          titles={props.titles}
          data={dataSizes}
          syncDataSizesCallback={props.syncDataSizesCallback}
          addNewSizeCallback={props.addNewSizeCallback}
          removeSizeCallback={props.removeSizeCallback}
        />
      )}
    </Wrapper>
  );
}
