import styled from '@emotion/styled';

const Wrapper = styled.span`
  display: inline-flex;
  width: 20px;
  height: 20px;
  animation: loading 1.4s linear infinite;
  .circle-loading {
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    -webkit-animation: animation-1p2h4ri 1.4s ease-in-out infinite;
    animation: animation-1p2h4ri 1.4s ease-in-out infinite;
  }
  @keyframes animation-1p2h4ri {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Loading() {
  return (
    <Wrapper className="loading-indicator">
      <svg viewBox="22 22 44 44">
        <circle
          className="circle-loading"
          cx="44"
          cy="44"
          r="20.2"
          fill="none"
          strokeWidth="3.6"
        ></circle>
      </svg>
    </Wrapper>
  );
}
