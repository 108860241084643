function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const formatDate = stringDate => {
  const object = new Date(stringDate);
  const year = object.getFullYear();
  const month = padWithLeadingZeros(object.getMonth() + 1, 2);
  const date = padWithLeadingZeros(object.getDate(), 2);

  return `le ${date}/${month}/${year}`;
};

export default function dateCommentFormat(stringDate) {
  const object = new Date(stringDate);
  const year = object.getFullYear();
  const month = padWithLeadingZeros(object.getMonth() + 1, 2);
  const date = padWithLeadingZeros(object.getDate(), 2);
  const hour = padWithLeadingZeros(object.getHours(), 2);
  const minute = padWithLeadingZeros(object.getMinutes(), 2);

  return `le ${year}/${month}/${date} à ${hour}h${minute}`;
}
