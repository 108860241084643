/* eslint-disable max-lines */
import styled from '@emotion/styled';
import SelectItem from 'components/ui/multiple-select/SelectItem';
import IconButton from 'components/ui/button/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Message from 'components/ui/message';
import SizeTable from 'project/tailles/SizeTable';
import ListTailles from 'project/tailles/ListTailles';
import {
  updateNickName,
  updateSizeQuantity,
  updateSizeUnit,
  downloadSizes,
  getProductInfo,
  deleteSizesTempFiles,
  removeSizeUnit,
  validateProduct,
} from 'utils/api';
import { useParams } from 'react-router-dom';
import ListTablePopIn from './ListTablePopIn';
import downloadFile from 'utils/downloadFile';
import { useState, useContext, useEffect } from 'react';
import Button from 'components/ui/button';
import ValidateButton from 'components/ui/validate-button';
import {
  CommentDispatchContext,
  ProjectContext,
  ProjectDispatchContext,
} from 'project';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import { TARGET_TAILLE_COMMENT } from 'project/comments';
import Trigger from 'rc-trigger';
import { today } from 'project/bat/BATItem';
import { formatDate } from 'project/bat/dateCommentFormat';
import Status from 'components/ui/status';

const Wrapper = styled('div')`
  .table {
    width: 100%;
    flex-wrap: wrap;
    border: 1px solid #e9e7ff;
    border-radius: 5px;
    .thead {
      width: 100%;
      border-bottom: 1px solid #e9e7ff;
      .tr {
        display: flex;
        .th {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow-wrap: anywhere;
          padding: 4px;
          & + .th {
            border-left: 1px solid #e9e7ff;
          }
        }
      }
    }
    .tbody {
      width: 100%;
      .tr {
        display: flex;
        & + .tr {
          border-top: 1px solid #e9e7ff;
        }
        .td {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          & + .td {
            border-left: 1px solid #e9e7ff;
          }
          input {
            background: #ffffff;
            border: 1px solid #e9e7ff;
            border-radius: 5px;
            max-width: 5rem;
            width: calc(100% - 10px);
            text-align: center;
          }
          .input_size_perso{
            max-width: 70rem;
          }
        }
      }
    }
    .tr {
      .th,
      .td {
        min-height: 45px;
        vertical-align: middle;
      }
      .th {
        background: #f6f5fb;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
      }
      .td {
        text-align: center;
      }
    }
  }
  .tailles-content {
    padding: 25px;
  }
  .accept-tailles {
    margin-top: 60px;
    margin-bottom: 25px;
  }

  .tailles-content_name_actions {
    .icon-button {
      height: 22px;
      & + .icon-button {
        margin-left: 20px;
      }
    }
  }
  .text-valid {
    font-weight: 600;
    font-size: 12px;
    line-height: 130.5%;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: #003c9d;
    }
  }
  .client-info_left_content_name {
    margin-right: 25px;
  }

  .btn-validate {
    pointer-events: auto;
    padding: 0px 24px;
    border-radius: 5px;
    background: #003c9d;
    color: #ffffff;
  }
  .btn-invalid {
    background: #909090;
  }

  @media only screen and (max-width: 900px) {
    .sizes-quantity {
      .table {
        .tbody {
          .tr {
            .td {
              input {
                width: calc(100% - 6px);
                max-width: 3rem;
              }
            }
          }
        }
      }
    }
    .sizes-list {
      .table {
        .thead {
          .tr {
            .th {
              &:nth-of-type(3),
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
        .tbody {
          .tr {
            .td {
              &:nth-of-type(3),
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .sizes-quantity {
      .table {
        .tbody {
          .tr {
            .td {
              &.total {
                min-width: 36px;
              }
              input {
                width: 100%;
                max-width: 3rem;
              }
            }
          }
        }
      }
    }
    .sizes-list {
      .table {
        .thead {
          .tr {
            .th {
              &:nth-of-type(3),
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
        .tbody {
          .tr {
            .td {
              &:nth-of-type(3),
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .sizes-quantity {
      .table {
        .thead {
          width: 50%;
          border-bottom: 0;
          .tr {
            flex-direction: column;
            .th {
              height: 40px;
              min-height: 40px;
              & + .th {
                border-top: 1px solid #e9e7ff;
              }
              border-left: 0 !important;
            }
          }
        }
        .tbody {
          width: 50%;
          .tr {
            display: flex;
            flex-direction: column;
            .td {
              height: 40px;
              min-height: 40px;
              border-bottom: 0 !important;
              input {
                width: 100%;
                max-width: 5rem;
              }
              border-left: 1px solid #e9e7ff;
              & + .td {
                border-top: 1px solid #e9e7ff;
              }
            }
          }
        }
      }
    }
    .sizes-list {
      .table {
        .thead {
          .tr {
            .th {
              &:nth-of-type(3),
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
        .tbody {
          .tr {
            .td {
              &:nth-of-type(3),
              &:nth-of-type(4) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .header {
    padding: 10px 0 30px;
  }
  @media only screen and (max-width: 600px) {
    .tailles-content {
      .tailles-content_size {
        border: 0;
        padding: 0;
        .header {
          padding-bottom: 15px;
        }
      }
      .title-taille {
        flex-direction: column-reverse;
        align-items: self-start !important;
      }
    }
  }
`;
const WrapperTooltip = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(75, 63, 180, 0.2);
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #c71f2c;
`;

type TaillesItemProps = {
  value: string | number | object;
  pdt: any;
  onQuantityChangeCallback: (value: number) => void;
};

const messageTypes = {
  warning: {
    style: 'warning',
    message:
      'Le nombre de tailles renseignées est inférieure à la quantité définie.',
  },
  dangerous: {
    style: 'dangerous',
    message:
      'Le nombre de tailles renseignées est supérieure à la quantité définie.',
  },
  success: {
    style: 'success',
    message:
      'Le nombre de tailles renseignées correspond bien à la quantité définie.',
  },
};

export default function TaillesItem({
  value,
  pdt,
  onQuantityChangeCallback,
}: TaillesItemProps) {
  const { data }: any = useContext(ProjectContext);
  const { id } = useParams();
  const dispatch = useContext(CommentDispatchContext);
  const projId = useParams().id;
  const total = pdt.quantity;
  const hasComment = pdt.has_comment_taille;
  const headerSizes = pdt.persos.label;
  const sizesDefault = pdt.persos.size_quantity;
  const dispatchProject: any = useContext(ProjectDispatchContext);
  const { data: dataProject }: any = useContext(ProjectContext);

  const { products, can_validate_bat_size: canValidateBatSize } =
    dataProject || {};
  const {
    is_validate_taille: isValidateTaille,
    validate_taille_on: validateTailleOn,
  } = pdt;
  const isValidate = data.is_validate || isValidateTaille;

  const getWarningBySizesANdTotal = (sumQuantity, totalQty): any => {
    if (totalQty === 0) return '';

    if (sumQuantity < totalQty) {
      return messageTypes.warning;
    }

    if (sumQuantity > totalQty) {
      return messageTypes.dangerous;
    }

    if (Number.parseInt(sumQuantity) === Number.parseInt(totalQty)) {
      return messageTypes.success;
    }
  };

  const [dataSizes, setDataSizes] = useState(
    typeof pdt.persos.value !== 'undefined' ? pdt.persos.value : [],
  );
  const [sizes, setSizes] = useState(sizesDefault);
  let pdtSumQty = sizes.reduce((sumQuantityq, size) => {
    return sumQuantityq + parseInt(size.quantity);
  }, 0);

  useEffect(() => onQuantityChangeCallback(pdtSumQty), []);

  const [sumQuantityRate] = useState(pdtSumQty);
  const [, setIsShowMessage] = useState(false);
  const [, setIsUpdateSuccess] = useState(false);
  const [messageType, setMessageType] = useState(
    getWarningBySizesANdTotal(sumQuantityRate, pdt.quantity),
  );

  const updateSizes = (index, input, sizeLabel, callBack) => {
    const sizesUpdate = [...sizes];
    sizesUpdate[index].quantity = input.value;
    setSizes(sizesUpdate);
    let sumQuantity = sizesUpdate.reduce((sumQuantityq, size) => {
      let quantity = parseInt(size.quantity);
      quantity = isNaN(quantity) ? 0 : quantity;

      return sumQuantityq + quantity;
    }, 0);
    callBack(sumQuantity);
    onQuantityChangeCallback(sumQuantity);

    if (!isNaN(parseInt(input.value)))
      updateSizeQuantity(projId, pdt.id, sizeLabel, parseInt(input.value));

    let messageObj = getWarningBySizesANdTotal(sumQuantity, total);
    setMessageType(messageObj);
  };

  const getFormattedPdtName = () => {
    let variant = pdt.variant ? `| ${pdt.variant}` : '';

    return `${pdt.name} ${variant}`;
  };

  const getPdtUrl = () => {
    return pdt.default_picture.startsWith('/files/')
      ? `/helpers/getfile.php?file=${pdt.default_picture}&d=s`
      : pdt.default_picture;
  };

  const getQuantityLabel = function (quantityDsp): string {
    return quantityDsp === 0 ? 'Non défini' : quantityDsp;
  };

  const delay = callback => {
    setTimeout(() => {
      callback();
    }, 3000);
  };

  const addNewSizes = object => {
    const newDataSizes = [...dataSizes];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        object[key] = {};
        object[key].value = '';
      }
    }
    newDataSizes.push(object);
    setDataSizes(newDataSizes);
  };

  const calculatorSizesData = newDataSizes => {
    const prepareDataSizes = {};
    newDataSizes.map(({ Taille }) => {
      // Change ID in Label value for size changed - Clement
      if (parseInt(Taille.value) > 0) {
        sizes.forEach(element => {
          if (element.id === Taille.value) Taille.value = element.size;
        });
      }

      if (prepareDataSizes[Taille.value]) prepareDataSizes[Taille.value]++;
      else prepareDataSizes[Taille.value] = 1;
    });

    const newSizes = [...JSON.parse(JSON.stringify(sizes))];
    sizes.map((row, index) => {
      newSizes[index].quantity = prepareDataSizes[row.size] ?? 0;
    });
    setSizes(newSizes);
    let sumQuantity = newSizes.reduce((sumQuantityq, size) => {
      let quantity = parseInt(size.quantity);
      quantity = isNaN(quantity) ? 0 : quantity;

      return sumQuantityq + quantity;
    }, 0);
    let messageObj = getWarningBySizesANdTotal(sumQuantity, total);
    setMessageType(messageObj);
  };

  const download = async name => {
    await downloadSizes(projId, pdt.id, name).then(() => {
      const variant = pdt.variant ? ` - ${pdt.variant}` : '';
      const filename = `${pdt.name}${variant} - tailles`;
      downloadFile(
        `/helpers/getfile.php?file=customerproject/${projId}/export/${name}&d=a`,
        `${filename}.xlsx`,
      );
    });
  };

  const downloadSizesTab = async () => {
    let prodId = pdt.id;
    await getProductInfo({ projId, prodId }).then(res => {
      let name = res.name;
      name = `tailles_${name.replace(/ /g, '_')}.xlsx`;
      download(name);
      deleteSizesTempFiles(projId);
    });
  };

  const removeSizeBySizeUnit = sizeUnit => {
    console.log(sizeUnit);

    if (sizeUnit === null) {
      const newDataSizes = [...dataSizes];

      for (let key in newDataSizes) {
        if (newDataSizes[key].Taille === '') {
          newDataSizes.splice(parseInt(key), 1);
          break;
        }
      }

      setDataSizes(newDataSizes);

      return;
    }

    removeSizeUnit(projId, pdt.id, sizeUnit).then(response => {
      if (response.error) {
        setIsShowMessage(true);
        setIsUpdateSuccess(false);
        delay(() => {
          setIsShowMessage(false);
        });
      } else {
        setIsShowMessage(true);
        setIsUpdateSuccess(true);
        setDataSizes(response);
        calculatorSizesData(response);
        delay(() => {
          setIsShowMessage(false);
        });
      }
    });
  };

  const syncDataSizes = (size, label, setvalue, index) => {
    const newObject = { ...JSON.parse(JSON.stringify(dataSizes[index])) };
    const newDataSizes = [...JSON.parse(JSON.stringify(dataSizes))];

    if (typeof newDataSizes[index][label] === 'object') {
      newObject[label].value = setvalue;
    }

    newDataSizes[index] = newObject;
    setDataSizes(newDataSizes);
    calculatorSizesData(newDataSizes);

    if (label === 'Taille') {
      let newQuantity = 0;
      newDataSizes.map(e => {
        if (e.Taille.size_unit !== 'unset') newQuantity++;
      });

      if (size.size_unit === 'unset') newQuantity++;
      onQuantityChangeCallback(newQuantity);

      updateSizeUnit(projId, pdt.id, setvalue, size.size_unit)
        .then(response => {
          if (response.error) {
            setIsShowMessage(true);
            setIsUpdateSuccess(false);
            delay(() => {
              setIsShowMessage(false);
            });
          } else {
            setIsShowMessage(true);
            setIsUpdateSuccess(true);
            setDataSizes(response);
            calculatorSizesData(response);
            delay(() => {
              setIsShowMessage(false);
            });
          }
        })
        .catch(() => {
          setIsShowMessage(true);
          setIsUpdateSuccess(false);
          delay(() => {
            setIsShowMessage(false);
          });
        });
    } else {
      updateNickName(
        projId,
        pdt.id,
        size.list_product_perso_id,
        size.size_id,
        setvalue,
      )
        .then(response => {
          if (response.error) {
            setIsShowMessage(true);
            setIsUpdateSuccess(false);
            delay(() => {
              setIsShowMessage(false);
            });
          } else {
            setIsShowMessage(true);
            setIsUpdateSuccess(true);
            delay(() => {
              setIsShowMessage(false);
            });
          }
        })
        .catch(() => {
          setIsShowMessage(true);
          setIsUpdateSuccess(false);
          delay(() => {
            setIsShowMessage(false);
          });
        });
    }
  };

  const handleValidateBat = () => {
    return validateProduct(id, { targetId: pdt.id, type: 'taille' }).then(
      () => {
        const productIndex = products.findIndex(item => +item.id === +pdt.id);

        if (productIndex !== -1) {
          const newProduct = [...products];
          newProduct[productIndex].is_validate_taille = true;
          newProduct[productIndex].validate_taille_on = today;
          dispatchProject({
            ...(dataProject || {}),
            products: newProduct,
          });
        }
      },
    );
  };

  const hasPerson = !!dataSizes.length;

  const handleOpenComment = () => {
    dispatch({
      type: TARGET_TAILLE_COMMENT,
      open: true,
      targetId: pdt.id,
      isValidate: isValidateTaille,
      hasPerson,
    });
  };

  const getButtonTrigger = () => {
    if (isValidateTaille) {
      return (
        <div className="text-valid accept-tailles">
          {hasPerson
            ? 'Tailles & personnalisations individuelles validées'
            : 'Tailles validées'}{' '}
          {formatDate(validateTailleOn)}
        </div>
      );
    }

    if (!canValidateBatSize || isValidate) {
      return <></>;
    }

    return (
      <Button type="primary" className="btn-validate accept-tailles">
        {hasPerson ? 'Valider les tailles & perso' : 'Valider les tailles'}
      </Button>
    );
  };

  const sumQuantity = sizes?.reduce((sumQuantityq, size) => {
    let quantity = parseInt(size.quantity);
    quantity = isNaN(quantity) ? 0 : quantity;

    return sumQuantityq + quantity;
  }, 0);

  return (
    <Wrapper id={`taille-${pdt.id}`}>
      <SelectItem value={value}>
        {() => (
          <>
            <div className={'tailles-content card'}>
              <div className={'tailles-content_name d-flex'}>
                {/* <div className={'tailles-content_name_checkbox'}>
                  {checkbox}
                </div> */}
                <div className={'tailles-content_name_logo'}>
                  <img src={getPdtUrl()} />
                </div>
                <div
                  className={
                    'tailles-content_name_title flex-column d-flex justify-content-around'
                  }
                >
                  <div className="d-flex align-item-center title-taille">
                    <div className={'client-info_left_content_name'}>
                      {getFormattedPdtName()}
                    </div>
                    {isValidateTaille && (
                      <Status
                        colorText="#51BA0F"
                        colorBg="#D1EEBF"
                        textStatus="Validées"
                      />
                    )}
                  </div>

                  <div className={'client-info_left_content_quantity'}>
                    Quantité : {getQuantityLabel(pdt.quantity)}
                  </div>
                </div>
                <div className="tailles-content_name_actions  ml-auto  d-flex">
                  <IconButton
                    icon={
                      hasComment ? (
                        <MarkChatUnreadOutlinedIcon />
                      ) : (
                        <AddCommentOutlinedIcon />
                      )
                    }
                    tooltip="Commenter"
                    onClick={handleOpenComment}
                  />

                  <IconButton
                    icon={<FileDownloadOutlinedIcon />}
                    tooltip="Télécharger"
                    onClick={downloadSizesTab}
                  />
                </div>
              </div>
              <div className={'card tailles-content_size'}>
                <div className={'header mb-6'}>Tailles</div>
                <div className={'content sizes-quantity'}>
                  <Message
                    type={messageType.style}
                    message={messageType.message}
                  />
                  <SizeTable
                    data={sizes}
                    dataSizes={dataSizes}
                    updateSizes={updateSizes}
                    total={total}
                    type={messageType.style}
                    isValidate={isValidate}
                  />
                </div>
              </div>
              {headerSizes.length > 1 ? (
                <div className={'card tailles-content_list'}>
                  <div className="header  d-flex flex-wrap justify-content-between">
                    <span className="mb-2">
                      Listes des personnalisations individuelles
                    </span>
                    {/*<div className="buttons">*/}
                    {/*  <UploadButton />*/}
                    {/*  <DownloadButton />*/}
                    {/*</div>*/}
                  </div>
                  <div className={'content sizes-list'}>
                    <ListTablePopIn
                      pdtQuantity={pdt.quantity}
                      titles={headerSizes}
                      sizes={sizes}
                      data={dataSizes.slice(0, 5)}
                      syncDataSizesCallback={syncDataSizes}
                      addNewSizeCallback={addNewSizes}
                      removeSizeCallback={removeSizeBySizeUnit}
                      lastACtionComponent={
                        <ListTailles
                          pdtQuantity={pdt.quantity}
                          sizes={sizes}
                          titles={headerSizes}
                          data={dataSizes}
                          syncDataSizesCallback={syncDataSizes}
                          addNewSizeCallback={addNewSizes}
                          removeSizeCallback={removeSizeBySizeUnit}
                        />
                      }
                    />
                  </div>
                </div>
              ) : ('')}
              <div className=" d-flex align-item-center justify-content-center">
                {messageType.style === 'success' ||
                (!pdt.quantity && +sumQuantity > 0) ? (
                  <ValidateButton
                    onSubmit={handleValidateBat}
                    buttonTrigger={getButtonTrigger()}
                    isValidate={isValidateTaille}
                    message={
                      hasPerson ? (
                        <>
                          Êtes-vous sûr de valider les tailles et les
                          personnalisations individuelles pour ce produit ?
                          <br />
                          Une fois validé, vous ne pourrez plus modifier les
                          éléments des tableaux ?
                        </>
                      ) : (
                        <>
                          Êtes-vous sûr de valider les tailles pour ce produit ?
                          <br />
                          Une fois validé, vous ne pourrez plus modifier les
                          éléments du tableau ?
                        </>
                      )
                    }
                    messageWarning={
                      hasPerson ? (
                        <>
                          Les tailles et personnalisations individuelles ont été
                          validées.
                          <br />
                          Vous ne pouvez plus modifier les éléments des
                          tableaux. <br />
                          Veuillez contacter la personne en charge de votre
                          dossier.
                        </>
                      ) : (
                        <>
                          Les tailles ont été validées. Vous ne pouvez plus
                          modifier les éléments des tableaux. Veuillez contacter
                          la personne en charge de votre dossier.
                        </>
                      )
                    }
                  />
                ) : (
                  <Trigger
                    action={['hover']}
                    popup={
                      <WrapperTooltip>
                        {+total !== 0
                          ? 'Les tailles saisies doivent correspondrent à la quantité renseignée'
                          : 'Veuillez saisir les tailles'}
                      </WrapperTooltip>
                    }
                    popupAlign={{
                      points: ['cc', 'bc'],
                      offset: [0, 10],
                    }}
                  >
                    {!canValidateBatSize || isValidate ? (
                      <></>
                    ) : (
                      <Button
                        type="primary"
                        className="btn-validate btn-invalid accept-tailles"
                      >
                        {hasPerson
                          ? 'Valider les tailles & perso'
                          : 'Valider les tailles'}
                      </Button>
                    )}
                  </Trigger>
                )}
              </div>
            </div>
          </>
        )}
      </SelectItem>
    </Wrapper>
  );
}
