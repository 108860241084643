import styled from '@emotion/styled';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import style from 'contanst/style';
import Loading from 'components/ui/loading';

const Wrapper = styled('button')<{
  buttonType: ButtonType;
  type: string;
  loading?: boolean;
  inactive?: boolean;
}>`
  height: 40px;
  min-width: 150px;
  font-weight: 700;
  font-size: 14px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  ${props =>
    props.loading &&
    'pointer-events: none;cursor: not-allowed;background: #808080 !important;'}
  ${props =>
    props.inactive &&
    'cursor: not-allowed;background: #909090 !important;color:#ffffff!important;border:0 !important;'}

  &.btn-primary {
    background: ${style.primaryColor};
    color: #ffffff;
    &:hover {
      background: #ffffff;
      color: ${style.primaryColor};
      border: 1px solid ${style.primaryColor};
      a {
        color: ${style.primaryColor};
      }
    }
  }
  &.btn-default {
    background: #ffffff;
    color: ${style.primaryColor};
    border: 1px solid ${style.primaryColor};
    &:hover {
      background: ${style.primaryColor};
      color: #ffffff;
    }
  }
  &[disabled] {
    background: #c5c5c5;
    border: 1px solid #c5c5c5;
    color: #ffffff;
    cursor: not-allowed;
    pointer-events: none;
  }
  .icon {
    + span {
      margin-left: 8px;
    }
  }
  a {
    color: #ffffff;
  }
`;

type ButtonType = 'primary' | 'default';

type ButtonProps = {
  children: ReactNode;
  type?: ButtonType;
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: ReactNode;
  loading?: boolean;
  inactive?: boolean;
  onClick?: (e) => void;
} & Omit<ButtonHTMLAttributes<any>, 'type' | 'onClick'>;

export default function Button({
  children,
  type = 'default',
  htmlType = 'button',
  icon,
  loading,
  inactive,
  onClick,
  className = '',
  ...extraProps
}: ButtonProps) {
  return (
    <Wrapper
      buttonType={type}
      inactive={inactive}
      onClick={onClick}
      type={htmlType}
      loading={loading}
      className={clsx(`btn-${type}`, className)}
      {...extraProps}
    >
      {!loading && icon && <span className="icon d-flex">{icon}</span>}
      {loading && (
        <span className="icon d-flex">
          <Loading />
        </span>
      )}
      <span>{children}</span>
    </Wrapper>
  );
}
