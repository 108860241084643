import styled from '@emotion/styled';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { ElementType, ReactNode, useCallback } from 'react';
import Button from 'components/ui/button';

const Wrapper = styled.div`
  height: 100%;
  form {
    height: 100%;
    .submit-form {
      button {
        & + button {
          margin-left: 20px;
        }
      }
    }
  }
`;

type FormProps = {
  onSubmit: (values: any, onCancel?) => Promise<any>;
  onFieldsChange?: (values: any, handleErrors?: (errors?) => void) => void;
  children: ReactNode;
  lblSubmit?: string;
  lblCancel?: string;
  showBtnCancel?: boolean;
  className?: string;
  clsFormContainer?: string;
  showButtonSubmit?: boolean;
  onCancel?: (e?, reset?: (values?) => void) => void;
  buttonSubmit?: ElementType;
  callbackSuccess?: () => void;
  checkEnableBtnSubmit?: boolean;
  initialValues?: object;
};

export default function Form({
  onSubmit,
  checkEnableBtnSubmit,
  children,
  onCancel,
  className = '',
  initialValues,
  callbackSuccess,
  showBtnCancel = true,
  lblSubmit = 'Enregistrer',
  lblCancel = 'Annuler',
}: FormProps) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
  });
  const { handleSubmit, formState, control } = methods;
  const { isSubmitting } = useFormState({ control });
  const handleSubmitCb = useCallback((values: any) => {
    return onSubmit(values, onCancel)
      .then(() => callbackSuccess && callbackSuccess())
      .catch();
  }, []);

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={event => {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit(handleSubmitCb)(event);
          }}
          className={` ${className}`}
        >
          <div className="container-form">{children}</div>
          <div className="submit-form d-flex justify-content-center">
            {showBtnCancel && (
              <Button type="default" onClick={onCancel}>
                {lblCancel}
              </Button>
            )}

            <Button
              loading={isSubmitting}
              disabled={checkEnableBtnSubmit && !formState.isValid}
              type="primary"
              htmlType="submit"
            >
              {lblSubmit}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Wrapper>
  );
}
