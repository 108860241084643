import styled from '@emotion/styled';
import RCCollapse from 'rc-collapse';
const Panel = RCCollapse.Panel;
import 'rc-collapse/assets/index.css';
import { ReactNode, useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import style from 'contanst/style';
import './motion.scss';
import type {
  CSSMotionProps,
  MotionEndEventHandler,
  MotionEventHandler,
} from 'rc-motion';

const getCollapsedHeight: MotionEventHandler = () => ({
  height: 0,
  opacity: 0,
});
const getRealHeight: MotionEventHandler = node => ({
  height: node.scrollHeight,
  opacity: 1,
});
const getCurrentHeight: MotionEventHandler = node => ({
  height: node.offsetHeight,
});
const skipOpacityTransition: MotionEndEventHandler = (_, event) =>
  (event as TransitionEvent).propertyName === 'height';

const collapseMotion: CSSMotionProps = {
  motionName: 'rc-collapse-motion',
  onEnterStart: getCollapsedHeight,
  onEnterActive: getRealHeight,
  onLeaveStart: getCurrentHeight,
  onLeaveActive: getCollapsedHeight,
  onEnterEnd: skipOpacityTransition,
  onLeaveEnd: skipOpacityTransition,
  motionDeadline: 500,
  leavedClassName: 'rc-collapse-content-hidden',
};

const Wrapper = styled(RCCollapse)`
  &.collapse {
    margin-top: 50px;
    border: 0;
    .rc-collapse-item {
      .rc-collapse-header {
        background: #ffffff;
        color: ${style.primaryColor};
        font-weight: 600;
        font-size: 20px;
        border: 1px solid #e9e7ff;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        .rc-collapse-expand-icon {
          font-size: 2em;
          svg {
            font-size: 0.8em;
          }
        }
        @media only screen and (max-width: 768px) {
          font-size: 18px;
        }
        * {
          color: ${style.primaryColor};
          font-weight: 600;
        }
        .rc-collapse-extra {
          display: flex;
          align-items: center;
          margin-left: auto !important;
          margin-right: 0 !important;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
      .rc-collapse-content {
        padding: 35px 25px;
        color: #000000;
        @media only screen and (max-width: 600px) {
          padding: 25px 15px;
        }
      }
    }
  }
`;

type CollapseProps = {
  header: ReactNode;
  children: ReactNode;
  id?: string;
  className?: string;
  enableCollapse?: boolean;
  defaultOpen?: boolean;
};

export default function Collapse({
  children,
  header,
  enableCollapse = false,
  defaultOpen = true,
  id,
  className = '',
}: CollapseProps) {
  const [activeKey, setActiveKey] = useState<any>(defaultOpen && ['collapse']);

  return (
    <div id={id} className={className}>
      <Wrapper
        accordion
        openMotion={collapseMotion}
        expandIcon={() =>
          enableCollapse ? (
            (activeKey || [])?.includes('collapse') ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )
          ) : undefined
        }
        className="collapse"
        activeKey={activeKey}
        onChange={v => enableCollapse && setActiveKey(v)}
      >
        <Panel header={header} key="collapse">
          {children}
        </Panel>
      </Wrapper>
    </div>
  );
}
