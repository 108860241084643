import styled from '@emotion/styled';
import { createContext, ReactNode, useState } from 'react';
import style from 'contanst/style';
import clsx from 'clsx';

const Wrapper = styled.div`
  .action {
    margin-left: 30px;
    color: ${style.primaryColor};
    &.disable {
      svg {
        color: #909090;
      }
    }
  }
`;

type MultipleSelectProps = {
  children: ReactNode;
  className?: string;
  id?: string;
  iconAction?: ReactNode;
  onClickAction?: (value) => void;
};
export const MultipleSelectContext = createContext<{
  selectedItems;
  setSelectedItems;
}>({
  selectedItems: undefined,
  setSelectedItems: undefined,
});

export default function MultipleSelect({
  children,
  className = '',
}: // onClickAction,
MultipleSelectProps) {
  const [selectedItems, setSelectedItems] = useState([]);
  const value = { selectedItems, setSelectedItems };
  // const valueItems: any = useMemo(() => {
  //   return Children.map(children, (child, index) => {
  //     if (!isValidElement(child)) {
  //       return '';
  //     }
  //
  //     return child?.props?.value;
  //   })?.filter(Boolean);
  // }, []);
  //
  // const indeterminate =
  //   !!selectedItems.length && selectedItems.length < valueItems.length;
  //
  // const handleSelectAll = selected => {
  //   setSelectedItems(prev => {
  //     if (!!prev.length && prev.length < valueItems.length) {
  //       return [];
  //     }
  //
  //     return selected && !indeterminate ? valueItems : [];
  //   });
  // };
  //
  // const handleClickAction = () => {
  //   selectedItems.length && onClickAction && onClickAction(selectedItems);
  // };

  return (
    <MultipleSelectContext.Provider value={value}>
      <Wrapper className={clsx(className, 'multiple-select')}>
        {/* <div className="select-all">
          <Checkbox
            onSelect={handleSelectAll}
            indeterminate={indeterminate}
            checked={
              !!selectedItems.length &&
              selectedItems.length === valueItems.length
            }
          />
          <IconButton
            icon={iconAction}
            disable={!selectedItems.length}
            onClick={handleClickAction}
            className="action"
          />
        </div> */}
        <div className="items">{children}</div>
      </Wrapper>
    </MultipleSelectContext.Provider>
  );
}
