import axios, { Method } from 'axios';

type Options = {
  method?: Method;
  data?: any;
  header?: any;
  params?: any;
  isUpload?: boolean;
  isFormData?: boolean;
};

export default (
  url,
  { method = 'GET', data, params, header = {}, isFormData }: Options = {},
): Promise<any> => {
  const isUpload = Object.values(data || {}).some(
    field => field instanceof File,
  );

  const headers: any = {
    'Content-Type': isUpload ? undefined : 'application/json',
    Accept: '*/*',
    ...header,
  };

  // if (isUpload || isFormData) {
  //   headers.type = 'formData';
  // }

  if (isFormData) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  if (isFormData) {
    const form = new FormData();
    Object.keys(data).map(key => {
      form.append(key, data[key]);
    });
    data = form;
  }

  return axios({
    method,
    url: `${process.env.REACT_APP_APICTOR_URL}/${url}`,
    data,
    headers,
    params,
  })
    .then(response => new Promise(resolve => resolve(response.data)))
    .catch(err => {
      return new Promise((resolve, reject) => reject(err.response.data));
    });
};
