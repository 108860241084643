import React from 'react';
import dateCommentFormat from 'project/bat/dateCommentFormat';
type Props = {
  comment: string;
  title: string;
  date: string;
  id?: string;
};

const CommentItem = ({ comment, title, date }: Props) => {
  return (
    <div className="comment-item">
      <div className="comment-title">{title}</div>
      <div className="comment-text">{comment}</div>
      <div className="comment-date">{dateCommentFormat(date)}</div>
    </div>
  );
};

export default CommentItem;
