import styled from '@emotion/styled';
import RcSelect, { Option } from 'rc-select';
import 'style/select.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode } from 'react';
import { SelectProps as RCSelectProps } from 'rc-select/lib/Select';
const Wrapper = styled(RcSelect)`
  .rc-select-selector {
    padding: 7px 8px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #e9e7ff;
    border-radius: 5px;
  }
`;

type OptionType = {
  value: string;
  label: string | ReactNode;
};

type SelectProps = {
  options: OptionType[];
} & RCSelectProps;

export default function Select({ options, ...extraProps }: SelectProps) {
  return (
    <Wrapper
      inputIcon={<ExpandMoreIcon />}
      menuItemSelectedIcon={null}
      {...extraProps}
    >
      {options?.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </Wrapper>
  );
}
