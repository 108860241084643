import Drawer from 'components/ui/drawer';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  CommentContext,
  CommentDispatchContext,
  ProjectContext,
} from 'project/index';
import styled from '@emotion/styled';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from 'components/ui/button/IconButton';
import CommentTailles from 'project/comments/CommentTailles';
import CommentDocuments from 'project/comments/CommentDocuments';
import CommentInformations from 'project/comments/CommentInformations';
import { getComments } from 'utils/api';
import { useParams } from 'react-router-dom';
import CommentAction from 'project/comments/CommentAction';
import LoadingIndicatior from 'components/loading-indicatior';

export const TARGET_INFORMATION_COMMENT = 'Information';
export const TARGET_TAILLE_COMMENT = 'Taille';
export const TARGET_DOCUMENT_COMMENT = 'Document';

const Wrapper = styled(Drawer)`
  background: #ffffff;
  position: relative;
  border: 1px solid #d9d9d9;
  height: 100vh;
  overflow: hidden;
  width: 660px;
  .close-comment {
    position: absolute;
    top: calc(50% + 10px);
    left: 15px;
    color: #000000;
    svg {
      color: #000000;
    }
  }
  .comment-header {
    //height: 35px;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 45px 30px 30px;
  }
  .comment-content {
    flex: 1 100%;
    padding: 0 70px;
    overflow: auto;
    scrollbar-color: #003c9d #f6f5fb;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f6f5fb;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #003c9d;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #003c9d;
    }
    .comment-section {
      min-height: 100px;
    }
    .collapse {
      margin-top: 0;
      .rc-collapse-item {
        .rc-collapse-header {
          flex-direction: row-reverse;
        }
        .rc-collapse-content {
          padding: 0 10px;
          .txt-empty-comment {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #808080;
            padding: 10px;
          }
          .comment-item {
            margin-bottom: 25px;
            .comment-title {
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 7px;
            }
            .comment-text {
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #000000;
              margin-bottom: 5px;
            }
            .comment-date {
              font-weight: 500;
              font-size: 12px;
              line-height: 130.5%;
              color: #909090;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 660px) {
    width: 100vw;
  }
  @media only screen and (max-width: 500px) {
    .comment-content {
      padding: 0 50px;
    }
    .comment-input {
      padding: 24px 50px;
    }
  }
  @media only screen and (max-width: 450px) {
    .comment-content {
      padding: 0 30px;
    }
    .comment-input {
      padding: 15px 30px;
    }
    .comment-header {
      padding-top: 30px;
    }
  }
`;

const WrapperContent = styled('div')``;

const mergeCommentToProduct = (comments: any = [], products: any = []) => {
  return comments.filter(item => {
    const product = products.find(pdt => +pdt.id === +item.target_id);

    if (product) {
      const variant = product.variant ? `| ${product.variant}` : '';
      item.title = `${product.name} ${variant}`;

      return item;
    }
  });
};

const mergeCommentToDocument = (comments: any = [], documents: any = []) => {
  return comments.filter(item => {
    const document = documents.find(
      doc => +doc.project_file_id === +item.target_id,
    );

    if (document) {
      item.title = document.name_user || document.name;

      return item;
    }
  });
};

const CommentContent = () => {
  const { id } = useParams();
  const data: any = useContext(CommentContext);
  const dispatch: any = useContext(CommentDispatchContext);
  const { data: dataProject }: any = useContext(ProjectContext);
  const { products, documents } = dataProject || {};
  const [loading, setLoading] = useState(false);
  const { comments, type } = data || {};
  const commentPrepare = useMemo(() => {
    const tailleComments = mergeCommentToProduct(
      comments?.taille_comment,
      products,
    );
    const documentComments = mergeCommentToDocument(
      comments?.document_comment,
      documents,
    );

    return {
      ...(comments || {}),
      taille_comment: tailleComments,
      document_comment: documentComments,
    };
  }, [comments, products, documents]);

  const fetchComments = () => {
    setLoading(true);
    getComments(id)
      .then(res => dispatch({ comments: res }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchComments();
  }, []);

  if (loading) {
    return (
      <WrapperContent className="comment-content d-flex align-item-center justify-content-center">
        <LoadingIndicatior />
      </WrapperContent>
    );
  }

  return (
    <WrapperContent className="comment-content">
      <CommentTailles
        open={type === TARGET_TAILLE_COMMENT}
        tailleComments={commentPrepare?.taille_comment}
      />
      <CommentDocuments
        open={type === TARGET_DOCUMENT_COMMENT}
        documentComments={commentPrepare?.document_comment}
      />
      <CommentInformations
        open={type === TARGET_INFORMATION_COMMENT}
        informationComments={commentPrepare?.information_comment}
      />
    </WrapperContent>
  );
};

export default function Comments() {
  const data: any = useContext(CommentContext);
  const dispatch: any = useContext(CommentDispatchContext);
  const { open } = data || {};

  const handleClose = () => {
    dispatch({ type: undefined, open: false });
  };

  return (
    <Wrapper open={open} className="d-flex flex-column">
      <IconButton
        className="close-comment"
        icon={<NavigateNextIcon />}
        tooltip="Close"
        onClick={handleClose}
      />
      <div className="comment-header d-flex align-item-center justify-content-center">
        Commentaires
      </div>
      <CommentContent />
      <CommentAction />
    </Wrapper>
  );
}
