import styled from '@emotion/styled';
import { forwardRef, ReactNode, useCallback, useEffect, useRef } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
const Wrapper = styled.div<any>`
  position: relative;
`;

type ScrollbarProps = {
  children: ReactNode;
  className?: string;
  onScroll?: (e) => void;
};

function Scrollbar(
  { children, onScroll, className = '' }: ScrollbarProps,
  ref,
) {
  const container = useRef<Element>();
  const ps = useRef<PerfectScrollbar>();

  const handleHover = useCallback(() => {
    if (ps.current) {
      ps.current.update();
    }
  }, [ps]);

  useEffect(() => {
    if (!container.current) {
      return undefined;
    }

    ps.current = new PerfectScrollbar(container.current, {});

    if (onScroll) {
      container.current?.addEventListener('ps-scroll-y', onScroll);
      container.current?.addEventListener('ps-scroll-x', onScroll);
    }

    return () => {
      if (onScroll) {
        container.current?.removeEventListener('ps-scroll-y', onScroll);
        container.current?.removeEventListener('ps-scroll-x', onScroll);
      }

      if (ps.current) {
        ps.current.destroy();
      }
    };
  }, []);

  return (
    <div ref={ref} className="scroll-container">
      <Wrapper ref={container} onMouseOver={handleHover} className={className}>
        {children}
      </Wrapper>
    </div>
  );
}

export default forwardRef(Scrollbar);
