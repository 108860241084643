import styled from '@emotion/styled';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import style from 'contanst/style';
import { ReactNode, useRef } from 'react';
import IconButton from 'components/ui/button/IconButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const Wrapper = styled.div``;
const WrapperMenu = styled(Menu)`
  &.dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 1px 4px #d2d2d2 !important;
    min-width: max-content;
    border-radius: 5px;
    padding: 12px 12px 10px 12px !important;
    color: ${style.primaryColor};
    font-weight: 500;
    font-size: 12px;
    position: relative;
    border: 0 !important;
    margin: 0;
    .close-menu {
      padding: 0 0 6px 6px;
      border-radius: 5px;
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
      user-select: none;
    }
    .menu-item {
      padding: 10px 20px 10px 10px;
      border-radius: 5px;
      margin-right: 24px;
      display: flex;
      align-items: center;
      &:hover {
        background: #f6f5fb;
      }
      .icon {
        margin-right: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

const MenuCustomize = ({ menuItems, closeRef }: MenuProps) => {
  return (
    <WrapperMenu className="dropdown-menu">
      <IconButton
        icon={<CloseOutlinedIcon />}
        className="close-menu"
        ref={closeRef}
      />
      {menuItems.map(({ icon, onClick, label, renderMenu }) => {
        if (renderMenu) {
          return renderMenu;
        }

        return (
          <MenuItem
            className="menu-item d-flex align-item-center pointer"
            onClick={onClick}
            key={label}
          >
            <span className="icon">{icon}</span>
            <span className="label">{label}</span>
          </MenuItem>
        );
      })}
    </WrapperMenu>
  );
};

type DropdownMenuProps = {
  menuItems: MenuItemType[];
  className?: string;
};

export default function DropdownMenu({
  menuItems,
  className = '',
}: DropdownMenuProps) {
  const closeRef = useRef();

  return (
    <Wrapper style={{ marginLeft: 'auto' }} className={className}>
      <Dropdown
        overlay={<MenuCustomize menuItems={menuItems} closeRef={closeRef} />}
        align={{ points: ['tr', 'tr'] }}
        trigger={['click']}
      >
        <IconButton icon={<MoreVertOutlinedIcon />} />
      </Dropdown>
    </Wrapper>
  );
}

type MenuItemType = {
  icon?: ReactNode;
  onClick?: (e) => void;
  label?: string;
  renderMenu?: ReactNode;
};
type MenuProps = {
  menuItems: MenuItemType[];
  closeRef: any;
};
